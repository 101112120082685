import React, { use, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useLocation } from "@reach/router";
import { Button } from "../../components";
import logo from "../../images/joosup.png";
import SuccessIcon from "../../images/successIcon.png";

const SubscriptionUpgrade = () => {
  const callbackClick = () => {
    const clickableLink = document.createElement("a");
    clickableLink.href =
      "joosup://Main/Settings/SubscriptionsStack/Subscriptions?type=upgradeProcessed";
    clickableLink.click();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          backgroundColor: "#F1F3F5",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 16px",
          }}
        >
          <img
            src={logo}
            alt="Joosup logo"
            style={{ maxWidth: "100%", height: "auto" }}
            maxWidth="360px"
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={SuccessIcon}
              alt="Success Icon"
              style={{
                maxWidth: "100%",
                height: "auto",
                alignSelf: "center",
                marginTop: 70,
              }}
            />
            <h2 style={{ fontWeight: 700, fontSize: 26, marginTop: 24 }}>
              Success
            </h2>
            <p style={{ fontWeight: 400, fontSize: 16, marginTop: 16 }}>
              Your subscription has been successfully upgraded. Any changes to your tariff will take effect in your account on the next billing cycle.
            </p>
          </div>
          <Button text="Go back to the app" onClick={callbackClick} />
        </div>
      </div>
    </>
  );
};

export default SubscriptionUpgrade;
