import React from "react";
import { Router } from "@reach/router";
import { Payment, Receipts, Subscriptions, SubscriptionUpgrade } from "./pages";
import Extension from "./pages/Extension/Extension";
import Invoices from "./pages/Invoices/Invoices";

const AppRouter = () => {
  return (
    <Router>
      <Payment path="/" />
      <Subscriptions path="/subscription" />
      <SubscriptionUpgrade path="/subscription-upgrade" />
      <Extension path="/extension" />
      <Receipts path="/receipts" />
      <Invoices path="/invoices" />
    </Router>
  );
};

export default AppRouter;
